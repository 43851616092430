import { Observable, Subject, defer, filter, from, fromEvent, map, merge, startWith, switchMap } from "rxjs"

/**
 * Observable from a broadcast channel.
 * @param name The broadcast channel name.
 * @returns An observable that emits messages from the broadcast channel.
 */
export function fromBroadcastChannel<T>(name: string) {
    return new Observable<T>(subscriber => {
        const channel = new BroadcastChannel(name)
        const subscription = fromEvent<MessageEvent>(channel, "message").pipe(map(_ => _.data)).subscribe(subscriber)
        return () => {
            subscription.unsubscribe()
            channel.close()
        }
    })
}

export class BroadcastSubject<T> extends Subject<T> {

    private readonly channel
    private readonly subscription

    constructor(key: string) {
        super()
        this.channel = new BroadcastChannel(key)
        this.subscription = fromEvent<MessageEvent>(this.channel, "message").pipe(map(_ => _.data)).subscribe({ next: super.next.bind(this), error: super.error.bind(this), complete: super.complete.bind(this) })
    }

    override next(value: T) {
        super.next(value)
        this.channel.postMessage(value)
    }
    override complete() {
        super.complete()
        this.subscription.unsubscribe()
        this.channel.close()
    }

}

/**
 * An observable that watches for a lock and emits true when the lock is held and false when it is not.
 * It uses a broadcast channel of the same name to be notified of the initial lock, so after acquiring the lock, the locking process should post a message to the broadcast channel.
 * @param lockName The lock name.
 * @param broadcastChannel The broadcast channel name.
 * @returns An observable that emits true when the lock is held and false when it is not.
 */
export function fromBroadcastAndLock(lockName: string, broadcastChannel = lockName) {
    return merge(
        fromBroadcastChannel(broadcastChannel),
        defer(() => navigator.locks.query()).pipe(
            filter(locks => locks.held?.some(_ => _.name === lockName) ?? false)
        )
    ).pipe(
        switchMap(() => {
            return from(navigator.locks.request(lockName, { mode: "shared" }, () => void 0)).pipe(
                map(() => false),
                startWith(true)
            )
        }),
        startWith(false)
    )
}

/**
 * Hack for observable-worker bug. TODO fix it.
 */
export function fromHack<T>(subscribable: Observable<T>) {
    return new Observable<T>(subscriber => {
        return subscribable.subscribe(subscriber)
    })
}
