import { BroadcastSubject } from "adapters/rxjs"
import Dexie, { IndexableType, Table } from "dexie"
import { Ramda } from "namespaces/Ramda"
import { RxJS } from "namespaces/RxJS"
import { AsyncStore } from "services/store/AsyncStore"

export interface DexieStoreConfig<K, V> {

    readonly table: Table<{ readonly key: K, readonly value: V }, K>

    /**
     * The name of the broadcast channel.
     */
    readonly broadcastChannelName: string

}

class AsyncDexie<K extends IndexableType, V> extends Dexie {

    readonly items

    constructor(key: string) {
        super(key)
        this.version(1).stores({
            items: "key"
        })
        this.items = this.table<{ readonly key: K, readonly value: V }, K>("items")
    }

}

export class DexieAsyncStore<K extends IndexableType, V> implements AsyncStore<K, V | undefined> {

    private readonly channel

    constructor(private readonly config: DexieStoreConfig<K, V>) {
        this.channel = new BroadcastSubject<readonly K[]>("dexie-store-" + config.broadcastChannelName)
    }

    async clear() {
        const keys = await this.keys()
        await this.config.table.clear()
        this.channel.next(keys)
    }
    async delete(key: K) {
        await this.config.table.delete(key)
        this.channel.next([key])
    }
    async deleteMulti(keys: readonly K[]) {
        await this.config.table.bulkDelete(keys as K[])
        this.channel.next(keys)
    }
    async get(key: K) {
        const entity = await this.config.table.get(key)
        if (entity === undefined) {
            return
        }
        return entity.value
    }
    async getMulti(keys: readonly K[]) {
        const entities = await this.config.table.bulkGet(keys as K[])
        return entities.map(entity => {
            if (entity === undefined) {
                return
            }
            return entity.value
        })
    }
    async getAll() {
        const entities = await this.config.table.toArray()
        return entities.map(entity => {
            return [entity.key, entity.value] as const
        })
    }
    async keys() {
        const entities = await this.config.table.toArray()
        return entities.map(entity => {
            return entity.key
        })
    }
    observe(key: K) {
        return this.channel.pipe(
            RxJS.filter(_ => _.includes(key)),
            RxJS.switchMap(() => this.get(key))
        )
    }
    observeAll() {
        return this.channel.pipe(
            RxJS.mergeMap(async events => {
                return Ramda.zip(events, await this.getMulti(events))
            }),
            RxJS.mergeMap(entities => {
                return RxJS.from(entities)
            })
        )
    }
    async set(key: K, value: V | undefined) {
        if (value === undefined) {
            await this.delete(key)
            return
        }
        const entity = { key, value }
        await this.config.table.put(entity)
        this.channel.next([key])
    }
    async setMulti(items: readonly (readonly [K, V | undefined])[]) {
        const remove = items.filter(item => item[1] === undefined).map(item => item[0])
        const set = items.filter(item => item[1] !== undefined).flatMap(([key, value]) => {
            if (value === undefined) {
                return []
            }
            return [{
                key,
                value
            }]
        })
        await this.config.table.bulkPut(set)
        await this.config.table.bulkDelete(remove)
        this.channel.next(items.map(item => item[0]))
    }
    async isClosed() {
        // return this.config.isClosed()
    }

    //TODO what about closing?
    static selfManaging<K extends IndexableType, V>(name: string) {
        const dexie = new AsyncDexie<K, V>(name)
        const close = () => dexie.close()
        const isClosed = () => !dexie.isOpen()
        return new DexieAsyncStore<K, V>({
            table: dexie.items,
            //  close,
            // isClosed,
            broadcastChannelName: name,
        })
    }

}
