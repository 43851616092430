import { useAccount, useGlobal, useStore } from "adapters/hooks"
import { fromHack } from "adapters/rxjs"
import { SYNTHETIC_DELAY } from "config"
import { RxJS } from "namespaces/RxJS"
import { useCallback, useMemo } from "react"
import DataManager from "services/DataManager"
import FileManager from "services/FileManager"
import Settings from "services/Settings"
import { CompanySchema, Schema } from "services/db/Schema"
import CompanyDefaultsPlugin from "services/db/kysely/CompanyDefaultsPlugin"
import CompanyDiscriminatorPlugin from "services/db/kysely/CompanyDiscriminatorPlugin"
import { ObservableKysely } from "services/db/kysely/ObservableKysely"
import { SAHWorkerDialect } from "services/db/kysely/WorkerKysely"
import { SqliteConnection } from "services/db/sqlite/connection"
import { GatewayBasedSqliteConnection } from "services/db/sqlite/gateway"
import PDFConverter from "services/rendering/PDFConverter"
import * as GenQLSync from "shared/genql/sync"
import { errorToString, getRootCause } from "shared/misc"
import { TemplateRenderer } from "shared/services"
import { useConstructor, useFactory, useOnline } from "state-hooks"
import { useAuth } from "ui/auth"
import PrefixedStore from "ui/store/PrefixedStore"

export default interface Core {

    readonly root: ObservableKysely<unknown>
    readonly db: ObservableKysely<Schema>
    readonly settings: Settings
    readonly pdfConverter: PDFConverter
    readonly hasura: GenQLSync.Client
    readonly renderer: TemplateRenderer
    readonly connection: SqliteConnection
    readonly dataManager: DataManager

}

export function useCompanyDb() {
    const auth = useAuth()
    const account = useAccount()
    return useFactory((userId, companyId, db) => {
        return db.withTables<CompanySchema>()
            .withPlugin(new CompanyDiscriminatorPlugin(companyId))
            .withPlugin(new CompanyDefaultsPlugin(companyId, userId))
    }, [
        auth.data.id,
        account.company.id,
        useDb<unknown>()
    ])
}
export function useFileManager() {
    const account = useAccount()
    const db = useCompanyDb()
    const fileStore = useUserFileStore()
    return useConstructor(FileManager, [account.company.id, db, fileStore])
}
export function useDataManager() {
    const worker = useWorker()
    const auth = useAuth()
    const store = useUserStore(auth.data.id)
    return useConstructor(DataManager, [store, worker, auth.logOut, auth.data.id])
}
export function useUserStore(id: number) {
    const store = useStore()
    return useMemo(() => new PrefixedStore(store, id + "-"), [store])
}
export function useSettings() {
    const auth = useAuth()
    const store = useStore()
    const userStore = useFactory((store, id) => new PrefixedStore(store, id + "-"), [store, auth.data.id])
    const db = useDb()
    const online = useOnline()
    return useConstructor(Settings, [userStore, online, auth.expired, db])
}
export function useWorker() {
    return useGlobal().proxy
}
export function useSyncer() {
    const worker = useWorker()
    const auth = useAuth()
    const settings = useSettings()
    return useCallback(() => {
        (async () => {
            try {
                const value = await worker.sync(auth.data.id, auth.data.token)
                console.log("[Sync] Received statistics from worker.", value)
                settings.lastSync.setValue({
                    date: Date.now(),
                    status: "success",
                    value: Object.fromEntries(value)
                })
            }
            catch (e) {
                settings.lastSync.setValue({
                    date: Date.now(),
                    status: "failure",
                    error: errorToString(getRootCause(e))
                })
            }
        })()
    }, [
        auth.data.id,
        auth.data.token,
        worker,
        settings,
    ])
}
export function useUserFileStore() {
    const global = useGlobal()
    const auth = useAuth()
    return useMemo(() => global.userFileStore(auth.data.id), [global, auth.data.id]) //TODO this is still iffy performance wise - it has to open dexie. look at making this stateful on global and then having prefixed stores under it or something
}
export function useDb<T = Schema>() {
    const auth = useAuth()
    const worker = useWorker()
    return useFactory(id => {
        const opener = (autoReconnect: boolean) => {
            return fromHack(worker.test()).pipe(
                RxJS.map(() => {
                    return async () => new GatewayBasedSqliteConnection(worker, await worker.open(id.toString()))
                })
            )
            /*
            return RxJS.of(void 0).pipe(
                RxJS.map(() => {
                    return async () => new GatewayBasedSqliteConnection(worker, await worker.open(id.toString()))
                })
            )*/
        }
        return new ObservableKysely<T>({ dialect: new SAHWorkerDialect(opener, o => o.pipe(RxJS.delay(SYNTHETIC_DELAY))) })
    }, [
        auth.data.id
    ])
}
