import { BindingSpec, SqlValue } from "@sqlite.org/sqlite-wasm"
import { Observable, from, switchMap } from "rxjs"
import { SqliteConnection } from "services/db/sqlite/connection"
import { SqliteConnectionManager } from "services/db/sqlite/manager"
import { SqliteChange } from "services/db/sqlite/util"
import { ValueOrFactory, callOrGet } from "value-or-factory"

export type PrefixArgs<T extends object, P extends readonly unknown[]> = {

    [K in keyof T]: T[K] extends (...args: any) => any ? ((...args: [...P, ...Parameters<T[K]>]) => ReturnType<T[K]>) : T[K]

}
export interface SqliteGateway<K = string, D = string> extends PrefixArgs<SqliteConnection, [D]> {

    open(key: K): PromiseLike<D>

}

export interface NewSqliteGateway<K = string> extends PrefixArgs<SqliteConnection, [K]> {
}

export class AutoOpeningSqliteGateway<K> implements NewSqliteGateway<K> {

    constructor(private readonly manager: SqliteConnectionManager<K>) {
    }

    private async open(id: K) {
        return await this.manager.open(id)
    }

    async query(id: K, sql: string, params?: BindingSpec | undefined) {
        const connection = await this.open(id)
        return await connection.query(sql, params)
    }
    observe(id: K, sql: string, params?: BindingSpec | undefined) {
        return from(this.open(id)).pipe(switchMap(connection => connection.observe(sql, params)))
    }
    close(id: K): PromiseLike<void> {
        throw new Error("Method not implemented.")
    }
    isClosed(id: K): PromiseLike<boolean> {
        throw new Error("Method not implemented.")
    }
    isOpen(id: K): PromiseLike<boolean> {
        throw new Error("Method not implemented.")
    }
    changed(id: K): Observable<SqliteChange> {
        throw new Error("Method not implemented.")
    }
    delete(id: K): PromiseLike<void> {
        throw new Error("Method not implemented.")
    }
    export(id: K): PromiseLike<Uint8Array> {
        throw new Error("Method not implemented.")
    }
    status(id: K): Observable<"open" | "closed"> {
        throw new Error("Method not implemented.")
    }

}

export interface DefaultSqliteGatewayConfig<K = string, D = string> {

    readonly manager: ValueOrFactory<PromiseLike<SqliteConnectionManager<K>>>
    readonly generateId: () => D

}

export class DefaultSqliteGateway<K = string, D = string> implements SqliteGateway<K, D> {

    protected readonly connections = new Map<D, SqliteConnection>()
    private readonly manager

    constructor(private readonly config: DefaultSqliteGatewayConfig<K, D>) {
        this.manager = callOrGet(config.manager)
    }

    async open(key: K) {
        const con = await (await this.manager).open(key)
        const id = this.config.generateId()
        this.connections.set(id, con)
        return id
    }

    private getConnection(key: D) {
        const connection = this.connections.get(key)
        if (connection === undefined) {
            throw new Error("Could not find a database connection for key " + key + ".")
        }
        return connection
    }

    isOpen(key: D) {
        return this.getConnection(key).isOpen()
    }
    isClosed(key: D) {
        return this.getConnection(key).isClosed()
    }
    async query(key: D, sql: string, params?: BindingSpec | undefined) {
        return await this.getConnection(key).query(sql, params)
    }
    observe(key: D, sql: string, params?: BindingSpec | undefined) {
        return this.getConnection(key).observe(sql, params)
    }
    close(key: D) {
        return this.getConnection(key).close()
    }
    delete(key: D) {
        return this.getConnection(key).delete()
    }
    export(key: D) {
        return this.getConnection(key).export()
    }
    changed(key: D) {
        return this.getConnection(key).changed()
    }
    status(key: D) {
        return this.getConnection(key).status()
    }

}

/**
 * A connection that falls back on a gateway.
 */
export class GatewayBasedSqliteConnection<K, C> implements SqliteConnection {

    constructor(private readonly gateway: NewSqliteGateway<C>, private readonly id: C) {
    }

    isOpen() {
        return this.gateway.isOpen(this.id)
    }
    isClosed() {
        return this.gateway.isClosed(this.id)
    }
    query(sql: string, params?: readonly SqlValue[] | undefined) {
        return this.gateway.query(this.id, sql, params)
    }
    observe(sql: string, params?: readonly SqlValue[] | undefined) {
        return this.gateway.observe(this.id, sql, params)
    }
    changed() {
        return this.gateway.changed(this.id)
    }
    export() {
        return this.gateway.export(this.id)
    }
    close() {
        return this.gateway.close(this.id)
    }
    delete() {
        return this.gateway.delete(this.id)
    }
    status() {
        return this.gateway.status(this.id)
    }

}
