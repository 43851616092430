import { SqlValue } from "@sqlite.org/sqlite-wasm"
import { createXXHash128 } from "hash-wasm"
import { Base64 } from "js-base64"
import { Ramda } from "namespaces/Ramda"
import { RxJS } from "namespaces/RxJS"
import { Observable } from "rxjs"

/**
 * Log queries by default if they take this long.
 */
export const DEFAULT_SLOW_QUERY_TIME = 2000

/**
 * Debounce all query observables by default.
 */
export const DEFAULT_SQLITE_OBSERVABLE_DEBOUNCE_TIME = 100

/**
 * The result of an SQLite query.
 */
export type SqliteResult = {

    readonly columns: readonly string[]
    readonly rows: readonly (readonly SqlValue[])[]

}

//export type SqliteTransactionState = "read" | "write" | "none"

export interface SqliteChange {

    readonly op: number
    readonly table: string

}

/**
 * Dumps a Sqlite value to a string.
 */
export function sqliteDump(value: SqlValue | boolean) {
    if (typeof value === "boolean") {
        return value ? "TRUE" : "FALSE"
    }
    if (value instanceof Uint8Array) {
        return [...value].map(n => n.toString(16).padStart(2, '0')).join('')
    }
    return value?.toString() ?? "NULL"
}

/**
 * Tests if a value is an sqlite value.
 */
export function sqliteTest(value: unknown): value is SqlValue {
    if (typeof value !== "string" && typeof value !== "number" && typeof value !== "bigint" && !(value instanceof Uint8Array) && value !== null) {
        return false
    }
    return true
}

export interface WatchQueryOptions {

    readonly debounceTime?: number | undefined
    readonly hash: (value: readonly SqliteResult[]) => unknown

}

/**
 * Observe a query. Note the order of the pipe. First, we check if it's locked (is this necessary?). Then we debounce. Then we start with void 0 - no need to debounce for the initial query.
 * @param changed 
 * @param queryLocked 
 * @param query 
 * @param options 
 * @returns 
 */
export function observeQuery(changed: Observable<unknown>, queryLocked: () => PromiseLike<void>, query: () => PromiseLike<readonly SqliteResult[]>, options: WatchQueryOptions) {
    return changed.pipe(
        //X.switchMap(queryLocked),
        RxJS.debounceTime(options.debounceTime ?? DEFAULT_SQLITE_OBSERVABLE_DEBOUNCE_TIME),
        RxJS.startWith(void 0),
        RxJS.switchMap(query),
        RxJS.distinctUntilChanged((a, b) => a === b, results => options.hash(results))
    )
}

/**
 * Zips results into objects.
 */
export function zipSqliteResults<R>(results: readonly SqliteResult[]) {
    return results.slice(-1).flatMap(result => result.rows.map(Ramda.zipObj(result.columns))) as unknown as R[]
}

export async function createHash() {
    const xxhash = await createXXHash128()
    return (value: unknown) => {
        const serialized = JSON.stringify(value, (_key, value) => {
            if (value instanceof Uint8Array) {
                return Base64.fromUint8Array(value)
            }
            return value
        })
        return xxhash.init().update(serialized).digest()
    }
}

/*
function serialize(value: unknown) {
    return
    if (value === undefined || value === null) {
        return null
    }
    else if (typeof value === "boolean" || typeof value === "number" || typeof value === "string") {
        return value
    }
    else if (typeof value === "bigint") {
        return value.toString()
    }
    else if (isTypedArray(value)) {
        if (value instanceof Uint8Array) {
            return Base64.fromUint8Array(value)
        }
        else {
            return Base64.fromUint8Array(new Uint8Array(value))
        }
    }
    else if (typeof value === "object") {
        return Object.fromEntries(Object.entries(value).map(([key, value]) => [key, serialize(value)]))
    }
    else if (Array.isArray(value)) {
        return value.map(serialize)
    }
    else {
        throw new Error("This value is not serializable.")
    }
}
*/