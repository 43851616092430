import { debounce } from 'throttle-debounce';

const DEFAULT_DEBOUNCE_TIME = 1;
class Batcher {
    flush;
    options;
    debounced;
    items = new Array();
    constructor(flush, options) {
        this.flush = flush;
        this.options = options;
        const debounceTime = options?.debounceTime ?? DEFAULT_DEBOUNCE_TIME;
        if (debounceTime > 0) {
            this.debounced = debounce(debounceTime, this.process.bind(this));
        }
        else {
            this.debounced = this.process.bind(this);
        }
    }
    process() {
        const items = this.items.splice(0);
        if (items.length > 0) {
            this.flush(items);
        }
    }
    add(item) {
        this.items.push(item);
        if (this.options?.flushTest?.(this.items) ?? false) {
            this.process();
        }
        else {
            this.debounced();
        }
    }
}

export { Batcher };
