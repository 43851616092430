import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { Alert, AlertProps } from "react-bootstrap"
import IconText from "ui/components/IconText"
import { LOADING_ICON } from "ui/icons"

export interface LoadingBarProps {

    readonly title?: string | undefined
    readonly border?: boolean | undefined
    readonly icon?: FontAwesomeIconProps
    readonly alert?: AlertProps | undefined

}

export default function LoadingBar(props: LoadingBarProps) {
    return (
        <Alert variant="light" className={classNames("bg-light", { border: props.border !== false, "border-0": props.border === false, "mb-0": props.border === false })} {...props.alert}>
            <IconText {...props.icon ?? LOADING_ICON} children={props.title ?? "Loading..."} />
        </Alert>
    )
}
