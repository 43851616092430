import { useMemo } from 'react';
import { useCustomCompareMemo } from './custom-compare.mjs';
import { useDeepCompareMemo } from './deep-compare.mjs';

//TODO remove the word memo
function useFactory(factory, deps) {
    return useMemo(() => factory(...deps), deps);
}
function useCustomCompareFactory(factory, deps, depsAreEqual) {
    return useCustomCompareMemo(() => factory(...deps), deps, depsAreEqual);
}
function useDeepCompareFactory(factory, deps) {
    return useDeepCompareMemo(() => factory(...deps), deps);
}

export { useCustomCompareFactory, useDeepCompareFactory, useFactory };
