
/**
 * - Some way to notify when the app has changed, and prompt for a reload?
 * - Multiple filters in table.
 * 
 * no easy way to edit the Hazards column on procedures page on a touch screen?
 * 
 * shrink camera on ipad or landscape
 * 
 * PHOTO FIELD IN AUDIT TOOL
 * MULTIPLE PHOTOS - NOTES PER PHOTO
 * 
 * test out delivery scan on a phone - ui is kinda sloppy looking
 * 
 * find a way to not have to do pdf chunks
 * procedure page - not all menu items disable whn you hit reload
 * add phone num to users
find and delete all relative links
/**
 * on bulk upload page - disable camera button while uploading
 * 
 * bulk upload overloads on large quantities of photos - performance issues?
 * 
 * template edit text box is very short

By setting gridOptions.suppressColumnMoveAnimation = true; before instantiating the agGrid instance and re-enabling it again a
fter the call to .applyColumnState(...), on the 1st render of the grid the columns are immediately rendered with the correct width and in the correct position according to the applied columnState.

Jul 13, 4:07 PM
Filter by date range on procedures

Jul 13, 4:15 PM
Report for procedure counts

Jul 17, 7:33 PM
Terms and conditions
 * mark everyone as accepted tos except for jason and anthony from ers

try to add a user to a company - all checkboxes remain locked except the first

Jul 1, 7:39 PM
Better error msg for camera

why the fuck is the procedure dropdown when creating an inspection item not async? its slow as shit
https://app.thelotogroup.com/company/16/audit/cBS9mNBoRJSrR44H83EEPw/create

reduce form col size

move open button in tables to the left

add ability to revise asset photo to audits

 */

import { Ramda } from "namespaces/Ramda"

/**
 * Convenience types.
 */
export const ONE: 0 | 1 = 1
export const ZERO: 0 | 1 = 0

/**
 * A bootstrap break.
 */
export type Break = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

/**
 * Require only the specified keys.
 */
export type RequireOnly<T, K extends keyof T> = Partial<{ [X in keyof Omit<T, K>]: T[X] | undefined | null }> & Pick<T, K>

/**
 * Get the data type from a data URL.
 * @param data Data URL.
 * @returns A data type.
 */
export function typeFromDataUrl(data: string) {
    return data.substring(data.indexOf(":") + 1, data.indexOf(";"))
}

/**
 * A utility function to create a query string.
 * @param object An object of the query parameters.
 * @returns A query string.
 */
export function queryString(object: Record<string, string | undefined>) {
    return new URLSearchParams(Ramda.pickBy(Ramda.isNotNil, object)).toString()
}
