import { Fragment, ReactNode, createElement } from "react"
import { Maybe } from "state-hooks"
import { STANDARD_SPACING } from "ui/config"

export interface HeadingProps {

    readonly title: ReactNode
    readonly subtitle?: ReactNode | undefined
    readonly level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

}

export default function Heading(props: HeadingProps) {
    return (
        <div className="me-auto d-flex align-items-end">
            {
                createElement(props.level, {
                    className: "d-flex align-items-center mb-" + STANDARD_SPACING,
                    children: (
                        <Fragment>
                            {props.title}
                            <Maybe value={props.subtitle} children={subtitle => <small className={"text-muted ms-" + STANDARD_SPACING} style={{ fontSize: "60%" }}>{subtitle}</small>} />
                        </Fragment>
                    )
                })
            }
        </div>
    )
}
