import { useMemo } from 'react';
import { useCustomCompareMemo } from './custom-compare.mjs';
import { useDeepCompareMemo } from './deep-compare.mjs';
import { useMemoOnce } from './once.mjs';

function useConstructorOnce(factory) {
    return useMemoOnce(() => new factory());
}
function useConstructor(factory, deps) {
    return useMemo(() => new factory(...deps), deps);
}
function useCustomCompareConstructor(factory, deps, depsAreEqual) {
    return useCustomCompareMemo(() => new factory(...deps), deps, depsAreEqual);
}
function useDeepCompareConstructor(factory, deps) {
    return useDeepCompareMemo(() => new factory(...deps), deps);
}

export { useConstructor, useConstructorOnce, useCustomCompareConstructor, useDeepCompareConstructor };
