import { RawNode } from "kysely"
import { DefaultsPlugin } from "kysely-defaults-plugin"

export default class CompanyDefaultsPlugin extends DefaultsPlugin {

    static readonly TIMESTAMP_NODE = RawNode.create(["CAST((JULIANDAY('NOW') - 2440587.5) * 86400000.0 AS INT)"], [])

    constructor(companyId: number, userId: number) {
        super({
            tables: [
                {
                    table: [
                        "assets",
                        "assetTypes",
                        "audits",
                        "auditItems",
                        "auditItemControlPoints",
                        "controlPoints",
                        "controlPointTemplates",
                        "customFields",
                        "energyMeasures",
                        "energySources",
                        "hazards",
                        "inspectionReasons",
                        "inspectionFindings",
                        "inspectionFindingPhotos",
                        "inspectionItems",
                        "inspectionItemPhotos",
                        "inspections",
                        "isolationDevices",
                        "languages",
                        "locations",
                        "procedures",
                        "procedureHazards",
                        "procedureTemplates",
                        "procedureTypes",
                        "severities",
                        "statuses",
                        "templates",
                        "translations",
                        "uploads"
                    ],
                    defaults: {
                        archived: [0],
                    }
                },
                {
                    table: "uploads",
                    overrides: {
                        present: [0],
                        resized: [0]
                    }
                },
                {
                    table: "*",
                    overrides: {
                        companyId: [companyId],
                        createdById: [userId],
                        updatedById: [userId, userId],
                        createdOn: [CompanyDefaultsPlugin.TIMESTAMP_NODE],
                        updatedOn: [CompanyDefaultsPlugin.TIMESTAMP_NODE, CompanyDefaultsPlugin.TIMESTAMP_NODE],
                        isDirty: [1, 1]
                    }
                }
            ]
        })
    }

}
