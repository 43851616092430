import { customAlphabet } from 'nanoid';
import PLazy from 'p-lazy';
import { Subject, Observable, from, filter, dematerialize, takeUntil, concatWith, throwError, share, firstValueFrom } from 'rxjs';

const WORKER_CLOSE = Symbol();
function wrap(options) {
    const closed = new Subject();
    const connection = options.channel();
    const proxy = new Proxy({}, {
        get(_target, command) {
            if (typeof command === "symbol") {
                throw new Error("No symbol calls on a proxy.");
            }
            return (...data) => {
                const observable = new Observable(subscriber => {
                    const id = generateId();
                    const observable = from(connection.observe).pipe(filter(response => response.id === id), dematerialize(), takeUntil(closed.pipe(concatWith(throwError(() => new Error("This remote is closed."))))));
                    const subscription = observable.subscribe(subscriber);
                    connection.send({
                        kind: "S",
                        id,
                        command,
                        data,
                    });
                    return () => {
                        subscription.unsubscribe();
                        connection.send({
                            kind: "U",
                            id
                        });
                    };
                });
                const shared = observable.pipe(share());
                return new ObservableAndPromise(shared, PLazy.from(() => firstValueFrom(shared)));
            };
        }
    });
    const close = () => {
        closed.complete();
        connection.close();
    };
    return {
        proxy,
        close
    };
}
/**
 * Utility class for combining and observable and promise.
 */
class ObservableAndPromise extends Observable {
    observable;
    promise;
    constructor(observable, promise) {
        super(subscriber => this.observable.subscribe(subscriber));
        this.observable = observable;
        this.promise = promise;
    }
    then(onFulfilled, onRejected) {
        return this.promise.then(onFulfilled, onRejected);
    }
}
/**
 * Generate a unique string ID.
 */
function generateId() {
    return customAlphabet("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", 22)();
}

export { ObservableAndPromise, WORKER_CLOSE, generateId, wrap };
