
import AppWorker from "app/AppWorker"
import { IS_DEV } from "config"
import { broadcastFinderBatching, wrapMigrating } from "observable-worker"
import { DexieAsyncStore } from "services/store/DexieAsyncStore"
import { ID } from "shared/ids"
import PrefixedStore from "ui/store/PrefixedStore"
import WindowStore from "ui/store/WindowStore"

/**
 * This class is used to manage global services and state.
 */
export default class Global {

    /**
     * The worker proxy.
     */
    private readonly remote

    /**
     * The worker proxy.
     */
    readonly proxy

    /**
     * A store for settings.
     */
    readonly store

    /**
     * A page store for settings.
     */
    readonly pageStore

    /**
     * Creates a new global object.
     */
    constructor(window: Window) {
        this.store = new PrefixedStore(new WindowStore(window.localStorage), IS_DEV ? "c4-collection-" : "")
        //TODO this seems to persist betwen tabs?
        this.pageStore = new WindowStore(window.sessionStorage)
        const worker = new Worker(new URL("/src/app/AppWorker.ts", import.meta.url), { type: 'module' })
        this.remote = wrapMigrating<AppWorker>({ finder: broadcastFinderBatching() })
        //   this.remote = wrap<AppWorker>({ channel: Channel.batching(Channel.worker(new URL("/src/app/AppWorker.ts", import.meta.url), { type: 'module' })) })
        this.proxy = this.remote.proxy
        this.close = this.close.bind(this)
    }

    /**
     * Closes the global object.
     */
    close() {
        this.remote.close()
    }

    fileStore() {

    }

    //TODO remove all user/company stores - just specify your own keys
    //TODO rm
    userFileStore(id: number) {
        return DexieAsyncStore.selfManaging<readonly [number, ID], Blob | undefined>("blobs-" + id)
    }

}
