import { useContext } from 'react';

function useRequiredContext(context) {
    const value = useContext(context);
    if (value === undefined || value === null) {
        throw new Error("This context (" + context.displayName + ") is not in scope.");
    }
    return value;
}

export { useRequiredContext };
