import { Break } from "adapters"
import { ReactNode } from "react"
import Container from "react-bootstrap/Container"
import { LazyContext } from "react-lazy"
import PageTitle from "shared/layout/pagetitle/PageTitle"
import { INTERNAL_FULL_PAGE_LAZY } from "shared/locallazy"
import { Maybe } from "state-hooks"
import { FLUID_AT } from "ui/config"

export interface InternalLayoutProps {

    readonly title?: ReactNode | undefined//TODO make required again
    readonly subtitle?: ReactNode | undefined
    readonly flex?: Break | undefined
    readonly actions?: ReactNode | undefined
    readonly children?: ReactNode | undefined

}

export default function InternalLayout(props: InternalLayoutProps) {
    return (
        <Container fluid={FLUID_AT} className="d-flex flex-column flex-grow-1">
            <LazyContext.Provider value={INTERNAL_FULL_PAGE_LAZY}>
                <Maybe value={props.title} children={title => <PageTitle title={title} subtitle={props.subtitle} flex={props.flex} children={props.actions} />} />
                <div className="d-flex flex-column flex-grow-1">
                    {props.children}
                </div>
            </LazyContext.Provider>
        </Container>
    )
}
