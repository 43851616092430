import { useState, useEffect } from 'react';

function useMediaDevicesPromise(mediaDevices = navigator.mediaDevices) {
    const [promise, setPromise] = useState(navigator.mediaDevices.enumerateDevices());
    useEffect(() => {
        const listener = () => {
            setPromise(mediaDevices.enumerateDevices());
        };
        mediaDevices.addEventListener("devicechange", listener);
        return () => {
            mediaDevices.removeEventListener("devicechange", listener);
        };
    }, [
        mediaDevices
    ]);
    return promise;
}

export { useMediaDevicesPromise };
