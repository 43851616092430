import { useBoolean } from './boolean.mjs';
import { useEvent } from './event.mjs';

function useOnline() {
    const online = useBoolean(window.navigator.onLine);
    useEvent(window, "online", online.on);
    useEvent(window, "offline", online.off);
    return online.value;
}

export { useOnline };
