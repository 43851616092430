import { useEffect } from 'react';
import { useDeepCompareConstant } from './constant.mjs';

function useEvent(window, type, listener, options) {
    useEffect(() => {
        window.addEventListener(type, listener, options);
        return () => {
            window.removeEventListener(type, listener);
        };
    }, [
        window,
        type,
        listener,
        useDeepCompareConstant(options),
    ]);
}

export { useEvent };
