import { INTERNET_TEST_TIMEOUT } from "./config.js";
import { isTemporaryException } from "./http.js";
/**
 * Run a series of promises in sequence.
 * @param promises Promises.
 * @returns The results of the promises.
 */
export function promiseChain(promises) {
    return promises.reduce(async (promise, task) => {
        return promise.then(async (values) => {
            return values.concat(await task(values));
        });
    }, Promise.resolve(new Array()));
}
/**
 * Parse JSON and return a typed object.
 */
export function typedJson(json) {
    return JSON.parse(json);
}
/**
 * Throw an error.
 * @param error Error.
 * @returns A function that throws the error.
 */
export function throwError(error) {
    return () => {
        throw error;
    };
}
/**
 * Convert an error to a string.
 * @param e The error.
 * @returns A string.
 */
export function errorToString(e) {
    const cause = getRootCause(e);
    if (typeof cause === "object" && cause !== null && "message" in cause && typeof cause.message === "string") {
        return cause.message;
    }
    return `${cause}`;
}
/**
 * Get the root cause of an exception by traversing the cause property recursively.
 * @param e The exception.
 * @returns The root cause.
 */
export function getRootCause(e) {
    if (e instanceof Error && e.cause !== undefined && e.cause !== null) {
        return getRootCause(e.cause);
    }
    else {
        return e;
    }
}
/**
 * Validate a filename. Returns an error string on failure, or nothing if successful.
 * @param fileName The file name.
 * @returns An optional error.
 */
export function validateFileName(fileName) {
    if (fileName.includes("/")) {
        return "Includes an invalid character: /";
    }
    return;
}
/**
 * Test to see if you are online. More reliable than navigator online.
 */
export async function testInternet(url) {
    try {
        const fetched = await fetch(url, { signal: AbortSignal.timeout(INTERNET_TEST_TIMEOUT) });
        return fetched.ok;
    }
    catch (e) {
        if (e instanceof DOMException) {
            if (e.name === "AbortError") {
                return false;
            }
        }
        if (isTemporaryException(e)) {
            return false;
        }
        throw e;
    }
}
export function getMode(modeString) {
    if (modeString === "development") {
        return "development";
    }
    else if (modeString === "staging") {
        return "staging";
    }
    else {
        return "production";
    }
}
