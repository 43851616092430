import { useCallback, useEffect, useMemo } from 'react';

function useCallbackOnce(callback) {
    return useCallback(callback, []);
}
function useEffectOnce(effect) {
    return useEffect(effect, []);
}
function useMemoOnce(factory) {
    return useMemo(factory, []);
}

export { useCallbackOnce, useEffectOnce, useMemoOnce };
