import { useRef, useEffect } from 'react';

function useIsFirstMount() {
    const isFirst = useRef(true);
    if (isFirst.current) {
        isFirst.current = false;
        return true;
    }
    return isFirst.current;
}
function useUpdateEffect(effect, deps) {
    const firstMount = useIsFirstMount();
    return useEffect(() => {
        if (firstMount) {
            return;
        }
        return effect();
    }, deps);
}

export { useIsFirstMount, useUpdateEffect };
