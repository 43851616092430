import { useState, useEffect } from 'react';

function useThrower() {
    const [exception, setException] = useState();
    useEffect(() => {
        if (exception !== undefined) {
            throw exception;
        }
    }, [
        exception
    ]);
    return setException;
}

export { useThrower };
