import { GridApi, ICellRendererParams, IRowNode, ProcessCellForExportParams, RowNode, StartEditingCellParams, SuppressKeyboardEventParams } from "@ag-grid-community/core"
import { GridPlugin, GridReducer } from "adapters/grid/plugins"
import { Ramda } from "namespaces/Ramda"
import { toast } from "react-toastify"
import { Nil } from "shared/misc"

export function agFormatValue<R, V>(params: Pick<ICellRendererParams<R, V>, "value" | "formatValue">) {
    if (params.value === undefined || params.value === null) {
        return
    }
    return params.formatValue !== undefined ? params.formatValue(params.value) : ("" + params.value)
}

export function startEditingCellFromCellRenderer<R, V>(props: ICellRendererParams<R, V> | ICellRendererParams<R, V | Nil>, params?: Omit<StartEditingCellParams, "rowIndex" | "colKey">) {
    if (props.node.rowIndex === null || props.column === undefined) {
        throw new Error("Missing row index or column information.")
    }
    props.api.startEditingCell({
        ...params,
        rowIndex: props.node.rowIndex,
        colKey: props.column.getId(),
    })
}

/**
 * Get rows based on selected cell ranges.
 */
export function agGridRowsFromCellRanges<R>(api: GridApi<R>) {
    const ranges = api.getCellRanges()
    if (ranges === null) {
        return []
    }
    const rows = ranges.flatMap(range => {
        if (range.startRow === undefined || range.endRow === undefined) {
            return [];
        }
        const min = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
        const max = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
        return Ramda.range(min, max + 1).map(_ => api.getDisplayedRowAtIndex(_))
    })
    return rows.filter((row): row is RowNode<R> => row !== undefined)
}

/**
 * Suppresses enter on a cell.
 */
export const suppressEnter = <R>(params: SuppressKeyboardEventParams<R>) => {
    if (params.editing && params.event.code === "Enter") {
        return true
    }
    return false
}

export const processCellImport = <R>(params: ProcessCellForExportParams<R>) => {
    const valueParser = params.column.getColDef().valueParser
    if (valueParser !== undefined) {
        if (typeof valueParser === "string") {
            throw new Error("String value parsers are not supported.")
        }
        if (params.node === undefined || params.node === null) {
            return
        }
        return valueParser({
            oldValue: undefined,
            newValue: params.value,
            node: params.node ?? null,
            data: params.node.data,
            column: params.column,
            colDef: params.column.getColDef(),
            api: params.api,
            context: params.context
        })
    }
    else {
        return params.value
    }
}
export const processCellExport = <R>(params: ProcessCellForExportParams<R>) => {
    const valueFormatter = params.column.getColDef().valueFormatter
    if (valueFormatter !== undefined) {
        if (typeof valueFormatter === "string") {
            throw new Error("String value formatters are not supported.")
        }
        if (params.node === undefined || params.node === null) {
            return ""
        }
        return valueFormatter({
            value: params.value,
            node: params.node ?? null,
            data: params.node.data,
            column: params.column,
            colDef: params.column.getColDef(),
            api: params.api,
            context: params.context
        })
    }
    else {
        return params.value
    }
}

/**
 * A row node with data and an ID.
 */
export type FullRowNode<R> = IRowNode<R> & { data: R, id: string }

/**
 * Check whether a row node has data and an ID.
 * @param node A node.
 * @returns True or false.
 */
export const isFullRowNode = <R,>(node: IRowNode<R>): node is FullRowNode<R> => node.data !== undefined && node.id !== undefined

export interface RowMatch<R> {

    readonly id?: string | undefined
    readonly result: R

}

export function matchRows<I, O>(nodes: readonly IRowNode<I>[], outputs: readonly RowMatch<O>[], api: GridApi<I>) {
    if (nodes.length !== outputs.length) {
        throw new Error("Mismatched number of nodes and outputs (" + nodes.length + " nodes and " + outputs.length + " outputs).")
    }
    return outputs.map((output, index) => {
        const node = (() => {
            const id = output.id
            if (id !== undefined) {
                const node = api.getRowNode(id)
                if (node === undefined) {
                    throw new Error("Could not find row with ID #" + id + ".")
                }
                return node
            }
            else {
                const node = nodes.at(index)
                if (node === undefined) {
                    throw new Error("Could not find node at index #" + index + ".")
                }
                return node
            }
        })()
        return {
            node,
            result: output.result,
        }
    })
}

/**
 * Recursively flatten the plugin arrays.
 */
export function flattenPlugins<R>(plugins: GridPlugin<R> | GridPlugin<R>[]): GridReducer<R>[] {
    if (!Array.isArray(plugins)) {
        return [plugins]
    }
    return plugins.flatMap(plugin => {
        if (Array.isArray(plugin)) {
            return flattenPlugins(plugin)
        }
        else {
            return [plugin]
        }
    })
}
