export function maybe(value, mapperOrError, otherwise) {
    if (value === undefined || value === null) {
        if (otherwise !== undefined) {
            return otherwise();
        }
        else if (mapperOrError === undefined || typeof mapperOrError === "string" || typeof mapperOrError === "object") {
            if (typeof mapperOrError === "object") {
                throw mapperOrError;
            }
            else {
                throw new Error(mapperOrError ?? "This value is not defined.");
            }
        }
        else {
            return undefined;
        }
    }
    else {
        if (typeof mapperOrError === "function") {
            return mapperOrError(value);
        }
        else {
            return value;
        }
    }
}
export function maybeOr(mapperOrError, otherwise) {
    return (value) => {
        if (value === undefined || value === null) {
            return otherwise();
        }
        else {
            if (typeof mapperOrError === "function") {
                return mapperOrError(value);
            }
            else {
                return value;
            }
        }
    };
}
