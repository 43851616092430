import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { Break } from "adapters"
import { Fragment, ReactNode } from "react"

export interface CollapsingIconProps extends FontAwesomeIconProps {

    readonly collapse: Break
    readonly children?: ReactNode | undefined
    readonly title: string

}

export default function CollapsingIcon(props: CollapsingIconProps) {
    const { collapse, children, className, ...rest } = props
    return (
        <Fragment>
            <FontAwesomeIcon {...rest} className={props.className} /><span className={"d-none d-" + collapse + "-inline"}><span className="icon-right" />{children ?? rest.title}</span>
        </Fragment>
    )
}
