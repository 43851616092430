import { GridApi } from "@ag-grid-community/core"
import { Break } from "adapters"
import GridHook from "adapters/grid/GridHook"
import { Fragment } from "react"
import { Nav, Navbar } from "react-bootstrap"

export interface GridToolBarProps<R> {

    readonly api?: GridApi<R> | undefined
    readonly items?: GridHook<R>[] | undefined
    readonly position: "top" | "bottom"
    readonly collapse: Break

}

export default function GridToolBar<R>(props: GridToolBarProps<R>) {
    const api = props.api
    if (api === undefined) {
        return <Fragment />
    }
    const sorter = props.items ?? []
    const left = sorter.filter(item => {
        if (props.position === "top") {
            return item.position === "left" || item.position === undefined
        }
        else if (props.position === "bottom") {
            return item.position === "bottom-left"
        }
        else {
            return false
        }
    })
    const right = sorter.filter(item => {
        if (props.position === "top") {
            return item.position === "right"
        }
        else if (props.position === "bottom") {
            return item.position === "bottom-right"
        }
        else {
            return false
        }
    })
    if (left.length === 0 && right.length === 0) {
        return <Fragment />
    }
    return (
        <Navbar bg="light" className={"p-2 p-lg-0 border " + (props.position === "top" ? "border-bottom-0" : "border-top-0")}>
            <Nav className="me-auto">
                {
                    left.map(item => <Fragment key={item.key} children={item.render({ api, collapse: props.collapse })} />)
                }
            </Nav>
            <Nav className="mr-auto">
                {
                    right.slice().reverse().map(item => <Fragment key={item.key} children={item.render({ api, collapse: props.collapse })} />)
                }
            </Nav>
        </Navbar>
    )
}
