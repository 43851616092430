
import { fromBroadcastAndLock } from "adapters/rxjs"
import { SYNC_INTERVAL, SYNC_LOCK } from "config"
import { RxJS } from "namespaces/RxJS"
import { useMemo } from "react"
import { useObservable } from "react-use"
import { useUserStore } from "services/Core"
import { SYNC_TABLES, Schema } from "services/db/Schema"
import { ObservableKysely } from "services/db/kysely/ObservableKysely"
import { useOnline } from "state-hooks"
import { useAuth } from "ui/auth"
import { useSynchronousObservable } from "ui/observables"
import Store from "ui/store/Store"
import StoreItem from "ui/store/StoreItem"

export interface SyncSuccess {

    readonly status: "success"
    readonly date: number
    readonly value: Record<string, number>

}

export interface SyncFailure {

    readonly status: "failure"
    readonly date: number
    readonly error: string

}

export type SyncResult = SyncSuccess | SyncFailure

export function useIsSyncing() {
    return useObservable(useMemo(() => fromBroadcastAndLock(SYNC_LOCK), [SYNC_LOCK]), undefined)
}
export function useLastSync() {
    const auth = useAuth()
    const store = useUserStore(auth.data.id)
    return useSynchronousObservable(useMemo(() => new StoreItem<SyncResult>(store, "lastSync"), [store]))
}
export function useNextSync() {
    const online = useOnline()
    const auth = useAuth()
    const isSyncing = useIsSyncing()
    const lastSync = useLastSync()
    if (isSyncing === undefined) {
        return
    }
    if (!online) {
        return "offline" as const
    }
    if (auth.expired) {
        return "tokenExpired" as const
    }
    if (isSyncing) {
        return "syncing" as const
    }
    if (lastSync === undefined) {
        return Date.now()
    }
    return lastSync.date + SYNC_INTERVAL
    /*
    return this.isSyncing.pipe(
        RxJS.switchMap(isSyncing => {
            if (isSyncing) {
                return RxJS.of("syncing" as const)
            }
            return this.lastSync.pipe(
                RxJS.startWith(this.lastSync.getValue()),
                RxJS.switchMap(lastSync => {
                    if (lastSync === undefined) {
                        return RxJS.of(Date.now())
                    }
                    return this.syncInterval.pipe(RxJS.map(interval => lastSync.date + interval))
                })
            )
        })
    )*/
}

export default class {

    readonly dirties
    readonly syncInterval
    readonly lastSync
    // readonly nextSync
    readonly needsSync
    readonly isSyncing
    // readonly isMigrating

    constructor(store: Store, online: boolean, tokenExpired: boolean, db: ObservableKysely<Schema>) {
        this.syncInterval = RxJS.of(SYNC_INTERVAL)
        this.dirties = db.observeFieldTakeFirstOrThrow("count", qc => {
            return qc.selectFrom(sb => {
                return SYNC_TABLES.map(table => sb.selectFrom(table).where("isDirty", "=", 1).select(eb => eb.fn.count<number>("isDirty").as("count")))
                    .reduce((a, b) => a.unionAll(b))
                    .as("count")
            }).select(eb => {
                return eb.fn.sum<number>("count").as("count")
            })
        }).pipe(
            RxJS.share()
        )
        this.lastSync = new StoreItem<SyncResult>(store, "lastSync")
        this.needsSync = this.dirties.pipe(RxJS.map(_ => _ > 0))
        this.isSyncing = fromBroadcastAndLock(SYNC_LOCK)
        /*
        //this.isMigrating = fromBroadcastAndLock(MIGRATION_LOCK)
        //TODO just use offline as a boolean instead of an observable
        this.nextSync = RxJS.of(online).pipe(
            RxJS.startWith(online),
            RxJS.switchMap(online => {
                if (!online) {
                    return RxJS.of("offline" as const)
                }
                if (tokenExpired) {
                    return RxJS.of("tokenExpired" as const)
                }
                return this.isSyncing.pipe(
                    RxJS.switchMap(isSyncing => {
                        if (isSyncing) {
                            return RxJS.of("syncing" as const)
                        }
                        return this.lastSync.pipe(
                            RxJS.startWith(this.lastSync.getValue()),
                            RxJS.switchMap(lastSync => {
                                if (lastSync === undefined) {
                                    return RxJS.of(Date.now())
                                }
                                return this.syncInterval.pipe(RxJS.map(interval => lastSync.date + interval))
                            })
                        )
                    })
                )
            })
        )*/
    }

}
