import { useState } from 'react';
import { useCounter } from './counter.mjs';
import { useThrower } from './thrower.mjs';

function useAsyncCallback(func) {
    const runs = useCounter();
    const [result, setResult] = useState();
    const thrower = useThrower();
    return {
        isRunning: runs.count > 0,
        runningCount: runs.count,
        result,
        run: (...args) => {
            runs.increment();
            func(...args).then(result => {
                runs.decrement();
                setResult(result);
            }, e => {
                runs.decrement();
                thrower(e);
            });
        }
    };
}

export { useAsyncCallback };
