import { Suspense, lazy } from "react"
import { Route, Routes } from "react-router-dom"
import LoadingPage from "ui/lazy/LoadingPage"

const PrivateLazy = lazy(() => import("app/Private"))
const PublicLazy = lazy(() => import("public/Public"))

export default function Split() {
    return (
        <Routes>
            <Route path="public/*" element={<Suspense fallback={<LoadingPage title="Loading Components..." />}><PublicLazy /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<LoadingPage title="Loading Components..." />}><PrivateLazy /></Suspense>} />
        </Routes>
    )
}
