import { DiscriminatorPlugin } from "kysely-defaults-plugin";

export default class CompanyDiscriminatorPlugin extends DiscriminatorPlugin {

    constructor(id: number) {
        super({
            tables: [
                {
                    table: "*",
                    columns: {
                        companyId: id
                    }
                }
            ]
        })
    }

}
