import { useCustomCompareMemo } from './custom-compare.mjs';
import { useDeepCompareMemo } from './deep-compare.mjs';

/**
 * Compares a value to its previous value. If unchanged, emits the previous value (so that it can be compared by reference by other hooks).
 */
function useCustomCompareConstant(value, depsAreEqual) {
    return useCustomCompareMemo(() => value, [value], depsAreEqual);
}
/**
 * Compares a value to its previous value. If unchanged, emits the previous value (so that it can be compared by reference by other hooks).
 */
function useDeepCompareConstant(value) {
    return useDeepCompareMemo(() => value, [value]);
}

export { useCustomCompareConstant, useDeepCompareConstant };
