import { flow } from "fp-ts/function";
import { Base64 } from "js-base64";
import { parse, stringify, v4 } from "uuid";
export function uint8ArrayToId(array) {
    if (array.byteLength === 16) {
        return array;
    }
    else {
        throw new Error("This is not a valid ID (byte length is " + array.byteLength + ").");
    }
}
export function bufferToId(array) {
    if (ArrayBuffer.isView(array)) {
        return uint8ArrayToId(new Uint8Array(array.buffer, array.byteOffset, array.byteLength));
    }
    else {
        return uint8ArrayToId(new Uint8Array(array));
    }
}
export const newId = () => v4({}, new Uint8Array(16));
export const printId = (id) => stringify(id);
export const printIdBase64 = (id) => Base64.fromUint8Array(id, true);
export const parseId = flow(parse, uint8ArrayToId);
export const parseIdBase64 = flow(Base64.toUint8Array, uint8ArrayToId);
export function isId(id) {
    try {
        parseId(id);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function isIdBase64(id) {
    try {
        parseIdBase64(id);
        return true;
    }
    catch (e) {
        return false;
    }
}
export function parseIdOrString(id) {
    return typeof id === "string" ? parseId(id) : id;
}
export function parseIdOrStringBase64(id) {
    return typeof id === "string" ? parseIdBase64(id) : id;
}
export const convertIdUuidToBase64 = flow(parseId, printIdBase64);
export const convertIdBase64ToUuid = flow(parseIdBase64, printId);
