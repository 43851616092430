export const PHOTO_SIZES = [
    [24, 24],
    [48, 48],
    [96, 96],
    [192, 192],
    [384, 384],
    [768, 768],
    [1536, 1536]
];
export const APP_NAME = "C4";
export const ASSET_DELIMITER = " / ";
export const INTERNET_TEST_TIMEOUT = 1000;
