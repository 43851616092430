import GridHook from "adapters/grid/GridHook"
import { GridPlugin } from "adapters/grid/plugins"

export function useHookGridPlugin<R>(config: GridHook<R>): GridPlugin<R> {
    return options => {
        return {
            ...options,
            hooks: [
                ...options.hooks ?? [],
                config
            ]
        }
    }
}
