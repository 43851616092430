import useDefaultsGridPlugin from "adapters/app-grid/plugins/defaults"
import Grid from "adapters/grid/Grid"
import { useHookGridPlugin } from "adapters/grid/plugins/hook"
import { useMultiGridPlugin } from "adapters/grid/plugins/multi"
import { Fragment } from "react/jsx-runtime"
import { NavLoading } from "shared/locallazy"

export interface GridLoadingProps {

    readonly title?: string | undefined

}

export default function GridLoading(props: GridLoadingProps) {
    const plugin = useMultiGridPlugin([
        useDefaultsGridPlugin(),
        useHookGridPlugin({
            key: "loading",
            render: hookProps => <NavLoading collapse={hookProps.collapse} title={props.title ?? "Loading..."} />
        })
    ])
    return (
        <Fragment>
            <Grid plugin={plugin} />
        </Fragment>
    )
}
