export default {
    "scalars": [
        0,
        1,
        4,
        6
    ],
    "types": {
        "Boolean": {},
        "Int": {},
        "LoginOutput": {
            "data": [
                5
            ],
            "error": [
                4
            ],
            "timestamp": [
                6
            ],
            "__typename": [
                4
            ]
        },
        "PinOutput": {
            "expires": [
                1
            ],
            "token": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "String": {},
        "TokenData": {
            "expires": [
                1
            ],
            "token": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "timestamptz": {},
        "Query": {
            "no_queries_available": [
                4
            ],
            "__typename": [
                4
            ]
        },
        "Mutation": {
            "login": [
                2,
                {
                    "emailAddress": [
                        4,
                        "String!"
                    ],
                    "password": [
                        4,
                        "String!"
                    ]
                }
            ],
            "pin": [
                3,
                {
                    "pin": [
                        4,
                        "String!"
                    ]
                }
            ],
            "__typename": [
                4
            ]
        }
    }
};
