import { jsx } from 'react/jsx-runtime';
import { Fragment } from 'react';

function Maybe(props) {
    if (props.value === undefined || props.value === null) {
        return jsx(Fragment, { children: props.otherwise });
    }
    return jsx(Fragment, { children: props.children(props.value) });
}

export { Maybe };
