import { dequal } from 'dequal';
import { useCustomCompareEffect, useCustomCompareUpdateEffect, useCustomCompareCallback, useCustomCompareMemo } from './custom-compare.mjs';

function useDeepCompareEffect(effect, deps) {
    return useCustomCompareEffect(effect, deps, dequal);
}
function useDeepCompareUpdateEffect(effect, deps) {
    return useCustomCompareUpdateEffect(effect, deps, dequal);
}
function useDeepCompareCallback(callback, deps) {
    return useCustomCompareCallback(callback, deps, dequal);
}
function useDeepCompareMemo(factory, deps) {
    return useCustomCompareMemo(factory, deps, dequal);
}

export { useDeepCompareCallback, useDeepCompareEffect, useDeepCompareMemo, useDeepCompareUpdateEffect };
