import { typeFromDataUrl } from "adapters"
import { UploadURLError } from "company/media"
import { PICA_IDLE, UPLOADS_URL } from "config"
import loadImage from "image-promise"
import { QueryCreator } from "kysely"
import { RxJS } from "namespaces/RxJS"
import Pica from "pica"
import { readAsArrayBuffer, readAsDataURL } from "promise-file-reader"
import { Observable } from "rxjs"
import { CompanySchema } from "services/db/Schema"
import FileStore from "services/store/FileStore"
import { newId, parseIdOrString } from "shared/ids"
import { UploadURLRequest, UploadURLs, uploadAndThumbnailUrls } from "shared/upload"
import { MAX_PIXELS } from "ui/config"

/**
 * This class is used to manage file uploads.
 */
export default class FileManager {

    /**
     * This is a singleton instance of the Pica library.
     */
    private static readonly Pica = Pica({
        idle: PICA_IDLE
    })

    /**
     * Creates a new file manager.
     * @param companyId A company ID.
     * @param db A database query creator.
     * @param dexie A Dexie database.
     */
    constructor(private readonly companyId: number, private readonly db: QueryCreator<CompanySchema>, private readonly store: FileStore) {
    }

    /**
     * Gets the URLs for an upload.
     * @param request An upload meta.
     * @returns An observable of the URLs.
     */
    url(request: UploadURLRequest, online: boolean): Observable<UploadURLs | UploadURLError> {
        if (online) {
            const found = uploadAndThumbnailUrls(UPLOADS_URL, this.companyId, request)
            if (found !== undefined) {
                return RxJS.of(found)
            }
        }
        const id = parseIdOrString(request.id)
        return RxJS.merge(this.store.get([this.companyId, id]), this.store.observe([this.companyId, id])).pipe(
            RxJS.map(url => {
                if (url === undefined) {
                    if (online) {
                        return "missing" as const
                    }
                    return "offline" as const
                }
                else {
                    return {
                        url,
                        thumbnails: undefined
                    }
                }
            })
        )
    }

    /**
     * Creates a new upload.
     * @param name A file name.
     * @param data A blob or data URL.
     * @param width The width of the image.
     * @param height The height of the image.
     * @returns An upload.
     */
    private async createUpload(name: string, data: Blob, width: number | null = null, height: number | null = null) {
        const id = newId()
        await this.db.insertInto("uploads")
            .values({
                id: id,
                name: name,
                type: data.type,
                size: data.size,
                width: width,
                height: height
            })
            .execute()
        try {
            await this.store.set([this.companyId, id], data)
        }
        catch (e) {
            await this.db.deleteFrom("uploads").where("id", "=", id).execute()
            throw e
        }
        return await this.db.selectFrom("uploads")
            .where("id", "=", id)
            .selectAll()
            .executeTakeFirstOrThrow()
    }

    /**
     * Creates a new upload from an image.
     * @param data A data URL.
     * @param name A file name.
     * @returns An upload.
     */
    private async createFromImage(data: string, name: string) {
        const type = typeFromDataUrl(data)
        const image = await loadImage(data)
        const scale = Math.sqrt(MAX_PIXELS / (image.width * image.height))
        const ratio = Math.min(scale, 1)
        const canvas = document.createElement("canvas")
        canvas.width = ratio * image.width
        canvas.height = ratio * image.height
        console.log("[Services/File] Resizing with a ratio of " + ratio + " (" + canvas.width + "x" + canvas.height + ")...")
        const resized = await FileManager.Pica.resize(image, canvas)
        const blob = await FileManager.Pica.toBlob(resized, type)
        return await this.createUpload(name, blob, canvas.width, canvas.height)
    }

    /**
     * Creates a new upload from a data URL.
     * @param data A data URL.
     * @param name A file name.
     * @returns An upload.
     */
    async createFromData(data: string, name: string) {
        console.log("[Services/File] Creating upload...", { data, name })
        const type = data.substring("data:".length, data.indexOf(";base64"))
        if (!type.startsWith("image/")) {
            const blob = await (await fetch(data)).blob()
            return await this.createUpload(name, blob)
        }
        return await this.createFromImage(data, name)
    }

    /**
     * Creates a new upload from a file.
     * @param file A file.
     * @returns An upload.
     */
    async createFromFile(file: File) {
        console.log("[Services/File] Creating upload...", { file })
        if (!file.type.startsWith("image/")) {
            const buffer = await readAsArrayBuffer(file)
            const blob = new Blob([buffer], { type: file.type })
            return await this.createUpload(file.name, blob)
        }
        return await this.createFromImage(await readAsDataURL(file), file.name)
    }

}