
import { LicenseManager } from "@ag-grid-enterprise/core"
import { init } from "@sentry/browser"
import App from "app/App"
import { AG_GRID_LICENSE_KEY, IS_PROD, SENTRY_DSN } from "config"
import "index.css"
import Global from "services/Global"
import { renderRoot } from "ui/index"

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY)

if (import.meta.env.DEV) {
    if (import.meta.hot) {
        import.meta.hot.accept()
    }
}

if (IS_PROD) {
    init({
        dsn: SENTRY_DSN,
        beforeSend(event) {
            if (event.message === undefined) {
                return null
            }
            if (event.message === "ResizeObserver loop limit exceeded") {
                return null
            }
            if (event.message === "AG Grid: Set Filter has a Key Creator, but provided values are primitives. Did you mean to provide complex objects or enable convertValuesToStrings?") {
                return null
            }
            return event
        }
    })
}

renderRoot(<App global={new Global(window)} />)
