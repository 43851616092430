/**
 * Determine if an error is temporary.
 * @param error The error.
 * @returns Whether the error is temporary.
 */
export function isTemporaryException(error) {
    if (error instanceof HTTPError) {
        if (error.statusCode === 500 || error.statusCode === 503 || error.statusCode === 508) {
            return true;
        }
    }
    if (error instanceof Error) {
        if (error.message === "Failed to fetch") {
            return true;
        }
        if (error.cause !== undefined && error.cause !== null) {
            return isTemporaryException(error.cause);
        }
    }
    return false;
}
/**
 * An HTTP error based on a status code.
 */
export class HTTPError extends Error {
    statusCode;
    constructor(statusCode, message, options) {
        super(message, options);
        this.statusCode = statusCode;
    }
}
/**
 * Throw an exception if an http response is not 200 OK.
 * @param response An HTTP response.
 */
export async function checkResponse(response, allowed = []) {
    if (!response.ok && !allowed.includes(response.status)) {
        throw new HTTPError(response.status, "The HTTP request returned a " + response.status + " response: " + (await response.text()));
    }
}
