
import { Break } from "adapters"
import { Fragment, ReactNode } from "react"
import ReactDOMServer from "react-dom/server"
import { StaticRouter } from "react-router-dom/server"
import Heading from "shared/Heading"
import { maybe } from "shared/maybe"
import { Maybe } from "state-hooks"
import { STANDARD_SPACING, TITLE_SEPARATOR } from "ui/config"
import useTitlePart from "ui/title/useTitlePart"

export interface PageTitleProps {

    readonly title: ReactNode
    readonly subtitle?: ReactNode | undefined
    readonly flex?: Break | undefined
    readonly children?: ReactNode | undefined

}

export default function PageTitle(props: PageTitleProps) {
    const flex = "d-" + (props.flex ?? "xl") + "-flex"
    // const title = ReactDOMServer.renderToStaticMarkup(<StaticRouter location={""}>{props.title}{maybe(props.subtitle, subtitle => <Fragment> - {subtitle}</Fragment>)} - {[...props.windowTitleParts ?? []].join("")}</StaticRouter>).replace(/<\/?[^>]+(>|$)/g, "")
    const title = ReactDOMServer.renderToStaticMarkup(<StaticRouter location={""}>{props.title}{maybe(props.subtitle, subtitle => <Fragment> {TITLE_SEPARATOR} {subtitle}</Fragment>)}</StaticRouter>).replace(/<\/?[^>]+(>|$)/g, "")
    useTitlePart(title)
    return (
        <Fragment>
            <div className={flex + " align-items-center"}>
                <div className="me-auto d-flex align-items-end">
                    <Heading level="h1" title={props.title} subtitle={props.subtitle} />
                </div>
                <div className="ms-auto">
                    <Maybe value={props.children} children={actions => <div className={"d-grid d-sm-flex gap-" + STANDARD_SPACING + " mb-" + STANDARD_SPACING} children={actions} />} />
                </div>
            </div>
        </Fragment>
    )
}
