// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
const LoginOutput_possibleTypes = ['LoginOutput'];
export const isLoginOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isLoginOutput"');
    return LoginOutput_possibleTypes.includes(obj.__typename);
};
const PinOutput_possibleTypes = ['PinOutput'];
export const isPinOutput = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isPinOutput"');
    return PinOutput_possibleTypes.includes(obj.__typename);
};
const TokenData_possibleTypes = ['TokenData'];
export const isTokenData = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isTokenData"');
    return TokenData_possibleTypes.includes(obj.__typename);
};
const mutation_root_possibleTypes = ['mutation_root'];
export const ismutation_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "ismutation_root"');
    return mutation_root_possibleTypes.includes(obj.__typename);
};
const query_root_possibleTypes = ['query_root'];
export const isquery_root = (obj) => {
    if (!obj?.__typename)
        throw new Error('__typename is missing in "isquery_root"');
    return query_root_possibleTypes.includes(obj.__typename);
};
