import { useRef, useCallback, useMemo, useEffect } from 'react';
import { useIsFirstMount } from './updates.mjs';

function useCustomCompareCallback(callback, deps, depsEqual) {
    const ref = useRef(undefined);
    if (ref.current === undefined || !depsEqual(deps, ref.current)) {
        ref.current = deps;
    }
    return useCallback(callback, ref.current);
}
function useCustomCompareMemo(factory, deps, depsEqual) {
    const ref = useRef(undefined);
    if (ref.current === undefined || !depsEqual(deps, ref.current)) {
        ref.current = deps;
    }
    return useMemo(factory, ref.current);
}
function useCustomCompareEffect(effect, deps, depsEqual) {
    const ref = useRef(undefined);
    if (ref.current === undefined || !depsEqual(deps, ref.current)) {
        ref.current = deps;
    }
    return useEffect(effect, ref.current);
}
function useCustomCompareUpdateEffect(effect, deps, depsAreEqual) {
    const firstMount = useIsFirstMount();
    return useCustomCompareEffect(() => {
        if (firstMount) {
            return;
        }
        return effect();
    }, deps, depsAreEqual);
}

export { useCustomCompareCallback, useCustomCompareEffect, useCustomCompareMemo, useCustomCompareUpdateEffect };
